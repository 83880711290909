import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from 'components/button'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteRouteFromServer,
  editRouteOnServer,
  getRouteFromServer,
  getRoutesListFromServer,
} from 'store/modules/routes/actions'
import { Fieldset, Legend } from 'components/styles/fieldset'
import { getRoute, getRoutesIsLoading } from 'store/modules/routes/selectors'
import { Loader } from 'components/loader'
import { Input_mb, Label, Warning } from 'components/styles/form'
import { Select } from 'components/select'
import { UploadFiles } from 'components/uploadFiles'
import { getLandmarksIsLoading, getLandmarksList } from 'store/modules/landmarks/selectors'
import { getRegionsList } from 'store/modules/regions/selectors'
import { getRegionsListFromServer } from 'store/modules/regions/actions'
import { setLandmarksListFromServer } from 'store/modules/landmarks/reducer'
import { setRegionsListFromServer } from 'store/modules/regions/reducer'
import { getLandmarksByRegionIdFromServer } from 'store/modules/landmarks/actions'
import { BackBtn } from 'components/backBtn'
import { Item, List, Row } from 'components/styles/list'
import { Container_column } from 'components/styles/container'
import { Routes } from 'routes/routes'
import { setRouteFromServer } from 'store/modules/routes/reducer'
import { config } from 'config/config'
import { CoverImg, MapImg } from 'components/styles/img-audio'

export const EditRoutePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const route = useSelector(getRoute)
  const isLoading = useSelector(getRoutesIsLoading)
  const landmarksIsLoading = useSelector(getLandmarksIsLoading)
  const regionsList = useSelector(getRegionsList)
  const landmarksList = useSelector(getLandmarksList)

  const [routeTitle, setRouteTitle] = useState('')
  const onCreateRouteTitleInput = (e: FormEvent<HTMLInputElement>) => setRouteTitle(e.currentTarget.value)

  const [routeDuration, setRouteDuration] = useState('')
  const onCreateDurationInput = (e: FormEvent<HTMLInputElement>) => setRouteDuration(e.currentTarget.value)

  const [regionId, setRegionId] = useState<string | undefined>(undefined)
  const regionsSelect = (e: ChangeEvent<HTMLSelectElement>) => setRegionId(e.currentTarget.value)

  const [landmarkId, setLandmarkId] = useState<string | undefined>(undefined)
  const landmarkSelect = (e: ChangeEvent<HTMLSelectElement>) => setLandmarkId(e.currentTarget.value)

  const [nearbyStopAddress, setNearbyStopAddress] = useState('')
  const onNearbyStopAddressInput = (e: FormEvent<HTMLInputElement>) => setNearbyStopAddress(e.currentTarget.value)

  const [nearbyStopAddressMapUrl, setNearbyStopAddressMapUrl] = useState('')
  const onNearbyStopAddressMapUrlInput = (e: FormEvent<HTMLInputElement>) =>
    setNearbyStopAddressMapUrl(e.currentTarget.value)

  const [coverFilesState, setCoverFilesState] = useState<File[] | null>(null)
  const [mapFilesState, setMapFilesState] = useState<File[] | null>(null)

  const { id } = useParams<{ id: string }>()

  const goBack = () => history.goBack()

  const deleteRoute = () => {
    const c = confirm('Вы точно хотите удалить маршрут?')
    if (c && route) {
      dispatch(deleteRouteFromServer(id, route, goBack))
    }
  }

  const editRoute = () => {
    if (routeTitle.length > 50) {
      alert('Название маршрута должно быть меньше 50 знаков')
      return
    }

    if (nearbyStopAddress.length > 30) {
      alert('Адрес должен быть меньше 30 знаков')
      return
    }
    if (routeTitle && routeDuration && regionId && route) {
      dispatch(
        editRouteOnServer(
          route,
          id,
          coverFilesState,
          mapFilesState,
          routeTitle,
          +routeDuration,
          regionId,
          goBack,
          nearbyStopAddress,
          nearbyStopAddressMapUrl,
          landmarkId,
        ),
      )
    }
  }

  const onMarkerClick = (id: string) => history.push(Routes.editMarker + '/' + id)

  useEffect(() => {
    dispatch(getRouteFromServer(id))
    dispatch(getRoutesListFromServer())
    dispatch(getRegionsListFromServer())

    return () => {
      dispatch(setLandmarksListFromServer([]))
      dispatch(setRegionsListFromServer([]))
      dispatch(setRouteFromServer(null))
    }
  }, [])

  useEffect(() => {
    if (regionId) {
      dispatch(getLandmarksByRegionIdFromServer(regionId))
    }
  }, [regionId])

  useEffect(() => {
    setRouteTitle(route ? route.title : '')
    setRouteDuration(route ? route.duration.toString() : '')
    setNearbyStopAddress(route ? route.nearbyStopAddress : '')
    setNearbyStopAddressMapUrl(route ? route.nearbyStopAddressMapUrl : '')
    setRegionId(route ? route.region.id : undefined)
    setLandmarkId(route && route.landmark ? route.landmark.id : undefined)
  }, [route])

  return (
    <Container_column>
      {isLoading && <Loader loading={isLoading} />}
      {landmarksIsLoading && <Loader loading={landmarksIsLoading} />}

      <BackBtn onClick={goBack} />

      <Wrapper>
        <Fieldset>
          <Legend>Редактирование маршрута</Legend>

          <Select
            list={regionsList}
            onChange={regionsSelect}
            selectId="regions"
            selectName="regions"
            label="Регион"
            region={route ? route.region : undefined}
            selectedId={route ? route.region.id : undefined}
          />

          <Label htmlFor="title">
            Название маршрута ({routeTitle ? routeTitle.length : ''} из <Warning>50</Warning> знаков)
          </Label>
          <Input_mb
            onInput={onCreateRouteTitleInput}
            type="text"
            id="title"
            name="title"
            value={routeTitle}
            maxLength={50}
          />

          <Label htmlFor="duration">Длительность маршрута (в минутах)</Label>
          <Input_mb onInput={onCreateDurationInput} type="number" id="duration" name="duration" value={routeDuration} />

          <Label htmlFor="nearbyStopAddress">
            Адрес конца маршрута (улица, номер дома, {nearbyStopAddress ? nearbyStopAddress.length : ''} из{' '}
            <Warning>30</Warning> знаков)
          </Label>
          <Input_mb
            onInput={onNearbyStopAddressInput}
            type="text"
            id="nearbyStopAddress"
            name="nearbyStopAddress"
            value={nearbyStopAddress}
            maxLength={30}
          />

          <Label htmlFor="nearbyStopAddressMapUrl">Ссылка на карту конца маршрута (Яндекс или Google карты)</Label>
          <Input_mb
            onInput={onNearbyStopAddressMapUrlInput}
            type="text"
            id="nearbyStopAddressMapUrl"
            name="nearbyStopAddressMapUrl"
            value={nearbyStopAddressMapUrl}
          />

          <Select
            label="Ориентир (не обязательно)"
            list={landmarksList}
            onChange={landmarkSelect}
            selectId="landmarks"
            selectName="landmarks"
            disabled={!regionId}
            landmark={route && route.landmark ? route.landmark : undefined}
            selectedId={route && route.landmark && route.landmark.id ? route.landmark.id : undefined}
          />

          <UploadFiles
            multiple={false}
            label="Выбрать обложку (изображение 512х512 пикселей, jpg)"
            inputId="cover"
            inputName="coverFile"
            state={coverFilesState}
            setState={setCoverFilesState}
            limitFilesCount={1}
          />

          {route && route.coverImage && (
            <>
              <Files>Прикрепленные файлы:</Files>
              <File>- {route.coverImage.originalName}</File>
              <CoverImg src={config.baseUrl + '/' + route.coverImage.source} alt="cover" />
            </>
          )}

          <UploadFiles
            multiple={false}
            label="Выбрать карту (скриншот карты с маршрутом - 1440х646 пикселей, jpg)"
            inputId="map"
            inputName="mapFile"
            state={mapFilesState}
            setState={setMapFilesState}
            limitFilesCount={1}
          />

          {route && route.routeMapImage && (
            <>
              <Files>Прикрепленные файлы:</Files>
              <File>- {route.routeMapImage.originalName}</File>
              <MapImg src={config.baseUrl + '/' + route.routeMapImage.source} alt="map" />
            </>
          )}

          <Button delBtn={false} title="Сохранить" onClick={editRoute} />
        </Fieldset>

        <Fieldset>
          <Legend>Список точек маршрута</Legend>
          <List>
            {route &&
              route.markers.map((m) => {
                return (
                  <Row key={m.id} onClick={() => onMarkerClick(m.id)}>
                    <Item>Индекс - {m.indexNumber}</Item>
                    <Item>{m.title}</Item>
                  </Row>
                )
              })}
          </List>
        </Fieldset>
      </Wrapper>

      <Button delBtn title="Удалить маршрут" onClick={deleteRoute} />
    </Container_column>
  )
}

const Files = styled.div`
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  margin-bottom: 5px;
`

const File = styled.div`
  margin-bottom: 10px;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

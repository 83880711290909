import { request } from 'api'
import { AppDispatch } from 'store/store'
import { setError } from 'store/modules/errors/reducer'
import { setMarkerFromServer, setMarkersIsLoading } from 'store/modules/markers/reducer'
import { deleteAudiosInMarker, deleteImagesInMarker, uploadFiles } from 'store/helpers'
import { TMarker } from 'store/modules/markers/types'

export const createMarkerOnServer = (
  indexNumber: number,
  title: string,
  address: string,
  addressMapUrl: string,
  descriptionTitle: string,
  description: string,
  routeId: string,
  imageFilesState: File[],
  audioFilesState: File[] | null,
  reloadPage: () => void,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setMarkersIsLoading(true))
    try {
      const imageFileIds = await uploadFiles(imageFilesState, '/files/upload/image', 'images')
      const audioFileIds = audioFilesState
        ? await uploadFiles(audioFilesState, '/files/upload/audio', 'audios')
        : undefined

      await request.post('/markers', {
        addressMapUrl,
        indexNumber,
        title,
        address,
        descriptionTitle,
        description,
        routeId,
        galleryIds: imageFileIds,
        audioIds: audioFileIds,
      })
      reloadPage()
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setMarkersIsLoading(false))
    }
  }
}

export const getMarkerFromServer = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setMarkersIsLoading(true))
    try {
      const response = await request.get(`/markers/${id}`)
      dispatch(setMarkerFromServer(response.data.data))
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setMarkersIsLoading(false))
    }
  }
}

export const editMarkerOnServer = (
  marker: TMarker,
  id: string,
  indexNumber: number,
  title: string,
  address: string,
  addressMapUrl: string,
  descriptionTitle: string,
  description: string,
  routeId: string,
  imageFilesState: File[] | null,
  audioFilesState: File[] | null,
  goBack: () => void,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setMarkersIsLoading(true))
    try {
      if (imageFilesState) {
        console.log('del im')
        await deleteImagesInMarker(marker)
      }
      if (audioFilesState) {
        console.log('del au')
        await deleteAudiosInMarker(marker)
      }

      const imageFileIds = imageFilesState
        ? await uploadFiles(imageFilesState, '/files/upload/image', 'images')
        : undefined
      const audioFileIds = audioFilesState
        ? await uploadFiles(audioFilesState, '/files/upload/audio', 'audios')
        : undefined

      await request.patch(`/markers/${id}`, {
        id,
        indexNumber,
        title,
        address,
        addressMapUrl,
        descriptionTitle,
        description,
        routeId,
        galleryIds: imageFileIds,
        audioIds: audioFileIds,
      })
      goBack()
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setMarkersIsLoading(false))
    }
  }
}

export const deleteMarkerFromServer = (id: string, marker: TMarker, goBack: () => void) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setMarkersIsLoading(true))
    try {
      await deleteAudiosInMarker(marker)
      await deleteImagesInMarker(marker)
      await request.delete(`/markers/${id}`)
      goBack()
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setMarkersIsLoading(false))
    }
  }
}

export enum Routes {
  regions = '/',
  editRegion = '/region-edit',
  editRoute = '/route-edit',
  editLandmark = '/landmark-edit',
  editMarker = '/mark-edit',
  auth = '/auth',
  error = '/error',
  landmarks = '/landmarks',
  marks = '/marks',
  routes = '/routes',
}

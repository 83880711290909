import { request } from 'api'
import { AppDispatch } from 'store/store'
import { setError } from 'store/modules/errors/reducer'
import { setRouteFromServer, setRoutesIsLoading, setRoutesListFromServer } from 'store/modules/routes/reducer'
import { uploadFiles } from 'store/helpers'
import { TRouteById } from 'store/modules/routes/types'

export const createRouteOnServer = (
  coverFiles: File[],
  mapFiles: File[],
  title: string,
  duration: number,
  regionId: string,
  reloadPage: () => void,
  nearbyStopAddress: string,
  nearbyStopAddressMapUrl: string,
  landmarkId?: string,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setRoutesIsLoading(true))
    try {
      const coverFileIds = await uploadFiles(coverFiles, '/files/upload/image', 'images')
      const mapFileIds = await uploadFiles(mapFiles, '/files/upload/image', 'images')

      await request.post('/routes', {
        title,
        duration,
        regionId,
        landmarkId,
        coverImageId: coverFileIds ? coverFileIds[0] : undefined,
        routeMapImageId: mapFileIds ? mapFileIds[0] : undefined,
        nearbyStopAddress,
        nearbyStopAddressMapUrl,
      })
      reloadPage()
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setRoutesIsLoading(false))
    }
  }
}

export const editRouteOnServer = (
  route: TRouteById,
  id: string,
  coverFiles: File[] | null,
  mapFiles: File[] | null,
  title: string,
  duration: number,
  regionId: string,
  goBack: () => void,
  nearbyStopAddress: string,
  nearbyStopAddressMapUrl: string,
  landmarkId?: string,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setRoutesIsLoading(true))
    try {
      if (coverFiles && coverFiles.length > 0) {
        await request.delete(`/files/image/${route.coverImage.id}`)
      }

      if (mapFiles && mapFiles.length > 0) {
        await request.delete(`/files/image/${route.routeMapImage.id}`)
      }

      const coverFileIds = coverFiles ? await uploadFiles(coverFiles, '/files/upload/image', 'images') : null
      const mapFileIds = mapFiles ? await uploadFiles(mapFiles, '/files/upload/image', 'images') : null

      await request.patch(`/routes/${id}`, {
        title,
        duration,
        regionId,
        landmarkId,
        coverImageId: coverFileIds ? coverFileIds[0] : undefined,
        routeMapImageId: mapFileIds ? mapFileIds[0] : undefined,
        nearbyStopAddress,
        nearbyStopAddressMapUrl,
      })
      goBack()
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setRoutesIsLoading(false))
    }
  }
}

export const getRoutesListFromServer = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setRoutesIsLoading(true))
    try {
      const response = await request.get('/routes')
      dispatch(setRoutesListFromServer(response.data.data))
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setRoutesIsLoading(false))
    }
  }
}

export const getRouteFromServer = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setRoutesIsLoading(true))
    try {
      const response = await request.get(`/routes/${id}`, {
        params: {
          readonly: true,
        },
      })
      dispatch(setRouteFromServer(response.data.data))
    } catch (error) {
      if (error.response) {
        dispatch(setError(error.response.data))
      } else {
        console.log(error)
      }
    } finally {
      dispatch(setRoutesIsLoading(false))
    }
  }
}

export const deleteRouteFromServer = (id: string, route: TRouteById, goBack: () => void) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setRoutesIsLoading(true))
    try {
      if (route && route.coverImage) {
        await request.delete(`/files/image/${route.coverImage.id}`)
      }
      if (route && route.routeMapImage) {
        await request.delete(`/files/image/${route.routeMapImage.id}`)
      }
      await request.delete(`/routes/${id}`)
      goBack()
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setRoutesIsLoading(false))
    }
  }
}

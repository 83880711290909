import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab } from 'store/modules/ui/reducer'
import { EnumTabs } from 'store/modules/ui/types'
import { Container_row } from 'components/styles/container'
import { getRouteFromServer, getRoutesListFromServer } from 'store/modules/routes/actions'
import { getRoute, getRoutesIsLoading, getRoutesList } from 'store/modules/routes/selectors'
import { setRouteFromServer, setRoutesListFromServer } from 'store/modules/routes/reducer'
import { Fieldset, Legend } from 'components/styles/fieldset'
import { getMarkersIsLoading } from 'store/modules/markers/selectors'
import { Loader } from 'components/loader'
import { Input_mb, Label, Textarea, Warning } from 'components/styles/form'
import { Select } from 'components/select'
import { UploadFiles } from 'components/uploadFiles'
import { Button } from 'components/button'
import { createMarkerOnServer } from 'store/modules/markers/actions'
import { Item, List, Row, RowTitle } from 'components/styles/list'
import { Routes } from 'routes/routes'
import { useHistory } from 'react-router-dom'

export const MarksPage = () => {
  const dispatch = useDispatch()
  const routes = useSelector(getRoutesList)
  const isLoading = useSelector(getMarkersIsLoading)
  const routesIsLoading = useSelector(getRoutesIsLoading)
  const routeById = useSelector(getRoute)
  const history = useHistory()

  const [indexNumber, setIndexNumber] = useState<string | number>('')
  const onIndexNumberInput = (e: FormEvent<HTMLInputElement>) => setIndexNumber(+e.currentTarget.value)

  const [title, setTitle] = useState('')
  const onTitleInput = (e: FormEvent<HTMLInputElement>) => setTitle(e.currentTarget.value)

  const [address, setAddress] = useState('')
  const onAddressInput = (e: FormEvent<HTMLInputElement>) => setAddress(e.currentTarget.value)

  const [addressMapUrl, setAddressMapUrl] = useState('')
  const onAddressMapUrlInput = (e: FormEvent<HTMLInputElement>) => setAddressMapUrl(e.currentTarget.value)

  const [descriptionTitle, setDescriptionTitle] = useState('')
  const onDescriptionTitleInput = (e: FormEvent<HTMLInputElement>) => setDescriptionTitle(e.currentTarget.value)

  const [description, setDescription] = useState('')
  const onDescription = (e: FormEvent<HTMLTextAreaElement>) => setDescription(e.currentTarget.value)

  const [routeId, setRouteId] = useState<string | undefined>(undefined)
  const routesSelect = (e: ChangeEvent<HTMLSelectElement>) => setRouteId(e.currentTarget.value)

  const [routeIdForShowMarkers, setRouteIdForShowMarkers] = useState<string | undefined>(undefined)
  const routeSelectForShowMarkers = (e: ChangeEvent<HTMLSelectElement>) =>
    setRouteIdForShowMarkers(e.currentTarget.value)

  const [imageFilesState, setImageFilesState] = useState<File[] | null>(null)
  const [audioFilesState, setAudioFilesState] = useState<File[] | null>(null)

  const reloadPage = () => {
    location.reload()
  }

  const onMarkerClick = (id: string) => history.push(Routes.editMarker + '/' + id)

  const createMarker = () => {
    if (title.length > 70) {
      alert('Название должно быть меньше 70 знаков')
      return
    }

    if (address.length > 30) {
      alert('Адрес должен быть меньше 30 знаков')
      return
    }

    if (description.length > 2000) {
      alert('Описание точки должно быть меньше 2000 знаков')
      return
    }

    if (
      indexNumber &&
      title &&
      address &&
      addressMapUrl &&
      descriptionTitle &&
      description &&
      routeId &&
      imageFilesState
      // audioFilesState
    ) {
      dispatch(
        createMarkerOnServer(
          +indexNumber,
          title,
          address,
          addressMapUrl,
          descriptionTitle,
          description,
          routeId,
          imageFilesState,
          audioFilesState,
          reloadPage,
        ),
      )
    }
  }

  useEffect(() => {
    dispatch(setActiveTab(EnumTabs.marks))
    dispatch(getRoutesListFromServer())

    return () => {
      dispatch(setRoutesListFromServer([]))
      dispatch(setRouteFromServer(null))
    }
  }, [])

  useEffect(() => {
    if (routeIdForShowMarkers) {
      dispatch(getRouteFromServer(routeIdForShowMarkers))
    }
  }, [routeIdForShowMarkers])

  return (
    <Container_row>
      {isLoading && <Loader loading={isLoading} />}
      {routesIsLoading && <Loader loading={routesIsLoading} />}

      <Fieldset>
        <Legend>Создание точки маршрута</Legend>

        <Select label="Маршрут" list={routes} onChange={routesSelect} selectId="routes" selectName="routes" />

        <Label htmlFor="index">Порядковый номер точки</Label>
        <Input_mb onInput={onIndexNumberInput} type="number" id="index" name="index" value={indexNumber} />

        <Label htmlFor="title">
          Название ({title ? title.length : ''} из <Warning>70</Warning> знаков)
        </Label>
        <Input_mb onInput={onTitleInput} type="text" id="title" name="title" value={title} maxLength={70} />

        <Label htmlFor="address">
          Адрес (улица, номер дома, {address ? address.length : ''} из <Warning>30</Warning> знаков)
        </Label>
        <Input_mb onInput={onAddressInput} type="text" id="address" name="address" value={address} maxLength={30} />

        <Label htmlFor="addressMapUrl">Ссылка на карту (Яндекс или Google карты)</Label>
        <Input_mb
          onInput={onAddressMapUrlInput}
          type="text"
          id="addressMapUrl"
          name="addressMapUrl"
          value={addressMapUrl}
        />

        <Label htmlFor="descriptionTitle">Заголовок описания</Label>
        <Input_mb
          onInput={onDescriptionTitleInput}
          type="text"
          id="descriptionTitle"
          name="descriptionTitle"
          value={descriptionTitle}
        />

        <Label htmlFor="description">
          Описание ({description ? description.length : ''} из <Warning>2000</Warning> знаков)
        </Label>
        <Textarea onInput={onDescription} id="description" name="description" value={description} maxLength={2000} />

        <UploadFiles
          multiple={true}
          label="Выбрать изображения для слайдера (1280х960 пикселей, jpg)"
          inputId="image"
          inputName="imageFile"
          state={imageFilesState}
          setState={setImageFilesState}
          limitFilesCount={5}
        />

        <UploadFiles
          multiple={true}
          label="Выбрать аудио (.wav)"
          inputId="audio"
          inputName="audioFile"
          state={audioFilesState}
          setState={setAudioFilesState}
          limitFilesCount={4}
        />

        <Button delBtn={false} title="Создать точку маршрута" onClick={createMarker} />
      </Fieldset>

      <Fieldset>
        <Legend>Список точек маршрута</Legend>
        <Select
          list={routes}
          onChange={routeSelectForShowMarkers}
          selectId="routes"
          selectName="routes"
          label="Маршрут"
        />

        {routeIdForShowMarkers && (
          <List>
            <RowTitle>
              <Item>Порядковый номер точки</Item>
              <Item>Название точки</Item>
            </RowTitle>
            {routeById &&
              routeById.markers.map((m) => {
                return (
                  <Row key={m.id} onClick={() => onMarkerClick(m.id)}>
                    <Item>{m.indexNumber}</Item>
                    <Item>{m.title}</Item>
                  </Row>
                )
              })}
          </List>
        )}
      </Fieldset>
    </Container_row>
  )
}

import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab } from 'store/modules/ui/reducer'
import { EnumTabs } from 'store/modules/ui/types'
import { Loader } from 'components/loader'
import { Button } from 'components/button'
import { getLandmarksIsLoading, getLandmarksList } from 'store/modules/landmarks/selectors'
import { getRegionsListFromServer } from 'store/modules/regions/actions'
import { getRegionsList } from 'store/modules/regions/selectors'
import { Item, Row, SmallList } from 'components/styles/list'
import { createLandmarkOnServer, getLandmarksByRegionIdFromServer } from 'store/modules/landmarks/actions'
import { setLandmarksListFromServer } from 'store/modules/landmarks/reducer'
import { Routes } from 'routes/routes'
import { useHistory } from 'react-router-dom'
import { Fieldset, Legend } from 'components/styles/fieldset'
import { Input_mb, Label } from 'components/styles/form'
import { Select } from 'components/select'
import { Container_column } from 'components/styles/container'

export const LandmarksPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector(getLandmarksIsLoading)
  const regionsList = useSelector(getRegionsList)
  const landmarksList = useSelector(getLandmarksList)
  const updateLandmarks = () => {
    if (showLandmarksRegionId) {
      dispatch(getLandmarksByRegionIdFromServer(showLandmarksRegionId))
    }
  }

  const [landmarkTitle, setLandmarkTitle] = useState('')
  const [createLandmarkRegionId, setCreateLandmarkRegionId] = useState<string | undefined>(undefined)
  const [showLandmarksRegionId, setShowLandmarksRegionId] = useState<string | undefined>(undefined)

  const onCreateLandmarkInput = (e: FormEvent<HTMLInputElement>) => setLandmarkTitle(e.currentTarget.value)
  const createLandmark = () => {
    if (landmarkTitle && createLandmarkRegionId) {
      dispatch(createLandmarkOnServer({ title: landmarkTitle, regionId: createLandmarkRegionId }, updateLandmarks))
      setLandmarkTitle('')
    }
  }
  const createLandmarkSelect = (e: ChangeEvent<HTMLSelectElement>) => setCreateLandmarkRegionId(e.currentTarget.value)

  const showLandmarkSelect = (e: ChangeEvent<HTMLSelectElement>) => setShowLandmarksRegionId(e.currentTarget.value)
  const onLandmarkClick = (id: string) => history.push(Routes.editLandmark + '/' + id)

  useEffect(() => {
    dispatch(setActiveTab(EnumTabs.landmarks))
    dispatch(getRegionsListFromServer())
    return () => {
      dispatch(setLandmarksListFromServer([]))
    }
  }, [])

  useEffect(() => {
    if (showLandmarksRegionId && showLandmarksRegionId !== 'Выберите...') {
      dispatch(getLandmarksByRegionIdFromServer(showLandmarksRegionId))
    }
  }, [showLandmarksRegionId])

  return (
    <Container_column>
      {isLoading && <Loader loading={isLoading} />}

      <Fieldset>
        <Legend>Создание ориентира</Legend>
        <Label htmlFor="title">Название ориентира</Label>
        <Input_mb onInput={onCreateLandmarkInput} type="text" name="title" value={landmarkTitle} />

        <Select
          label="Выбор региона"
          list={regionsList}
          onChange={createLandmarkSelect}
          selectId="regions"
          selectName="regions"
        />

        <Button delBtn={false} title="Создать ориентир" onClick={createLandmark} />
      </Fieldset>

      <Fieldset>
        <Legend>Ориентиры</Legend>

        <Select
          label="Выбор региона"
          list={regionsList}
          onChange={showLandmarkSelect}
          selectId="showLandmark"
          selectName="showLandmark"
        />

        {showLandmarksRegionId && landmarksList.length > 0 && (
          <>
            <SubTitle>Список ориентиров по региону:</SubTitle>
            <SmallList>
              {landmarksList.map((r) => {
                return (
                  <Row key={r.id} onClick={() => onLandmarkClick(r.id)}>
                    <Item>{r.title}</Item>
                    <Item>{r.routesCount} маршрутов</Item>
                  </Row>
                )
              })}
            </SmallList>
          </>
        )}
      </Fieldset>
    </Container_column>
  )
}

const SubTitle = styled.h5`
  margin-bottom: 20px;
`

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TRouteById, TRouteInList, TRoutesState } from 'store/modules/routes/types'

const initialState: TRoutesState = { routesIsLoading: false, route: null, routesList: [] }

const userSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setRoutesIsLoading(state: TRoutesState, action: PayloadAction<boolean>) {
      state.routesIsLoading = action.payload
    },
    setRouteFromServer(state: TRoutesState, action: PayloadAction<TRouteById | null>) {
      state.route = action.payload
    },
    setRoutesListFromServer(state: TRoutesState, action: PayloadAction<TRouteInList[]>) {
      state.routesList = action.payload
    },
  },
})

export const { setRoutesIsLoading, setRouteFromServer, setRoutesListFromServer } = userSlice.actions

export default userSlice.reducer

import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab } from 'store/modules/ui/reducer'
import { EnumTabs } from 'store/modules/ui/types'
import { getRoutesIsLoading, getRoutesList } from 'store/modules/routes/selectors'
import { Loader } from 'components/loader'
import { Item, List, Row, RowTitle } from 'components/styles/list'
import { Routes } from 'routes/routes'
import { useHistory } from 'react-router-dom'
import { createRouteOnServer, getRoutesListFromServer } from 'store/modules/routes/actions'
import { Fieldset, Legend } from 'components/styles/fieldset'
import { getRegionsListFromServer } from 'store/modules/regions/actions'
import { getRegionsList } from 'store/modules/regions/selectors'
import { Input_mb, Label, Warning } from 'components/styles/form'
import { getLandmarksIsLoading, getLandmarksList } from 'store/modules/landmarks/selectors'
import { getLandmarksByRegionIdFromServer } from 'store/modules/landmarks/actions'
import { setLandmarksListFromServer } from 'store/modules/landmarks/reducer'
import { setRegionsListFromServer } from 'store/modules/regions/reducer'
import { Button } from 'components/button'
import { Select } from 'components/select'
import { UploadFiles } from 'components/uploadFiles'
import { Container_row } from 'components/styles/container'
import { setRoutesListFromServer } from 'store/modules/routes/reducer'

export const RoutesPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector(getRoutesIsLoading)
  const landmarksIsLoading = useSelector(getLandmarksIsLoading)
  const routes = useSelector(getRoutesList)
  const regionsList = useSelector(getRegionsList)
  const landmarksList = useSelector(getLandmarksList)

  const reloadPage = () => location.reload()

  const [routeTitle, setRouteTitle] = useState('')
  const onCreateRouteTitleInput = (e: FormEvent<HTMLInputElement>) => setRouteTitle(e.currentTarget.value)

  const [routeDuration, setRouteDuration] = useState('')
  const onCreateDurationInput = (e: FormEvent<HTMLInputElement>) => setRouteDuration(e.currentTarget.value)

  const [nearbyStopAddress, setNearbyStopAddress] = useState('')
  const onNearbyStopAddressInput = (e: FormEvent<HTMLInputElement>) => setNearbyStopAddress(e.currentTarget.value)

  const [nearbyStopAddressMapUrl, setNearbyStopAddressMapUrl] = useState('')
  const onNearbyStopAddressMapUrlInput = (e: FormEvent<HTMLInputElement>) =>
    setNearbyStopAddressMapUrl(e.currentTarget.value)

  const [regionId, setRegionId] = useState<string | undefined>(undefined)
  const regionsSelect = (e: ChangeEvent<HTMLSelectElement>) => setRegionId(e.currentTarget.value)

  const [landmarkId, setLandmarkId] = useState<string | undefined>(undefined)
  const landmarkSelect = (e: ChangeEvent<HTMLSelectElement>) => setLandmarkId(e.currentTarget.value)

  const onRouteClick = (id: string) => history.push(Routes.editRoute + '/' + id)

  const createRoute = () => {
    if (routeTitle.length > 50) {
      alert('Название маршрута должно быть меньше 50 знаков')
      return
    }

    if (nearbyStopAddress.length > 30) {
      alert('Адрес должен быть меньше 30 знаков')
      return
    }

    if (routeTitle && routeDuration && regionId && coverFilesState && mapFilesState) {
      dispatch(
        createRouteOnServer(
          coverFilesState,
          mapFilesState,
          routeTitle,
          +routeDuration,
          regionId,
          reloadPage,
          nearbyStopAddress,
          nearbyStopAddressMapUrl,
          landmarkId,
        ),
      )
    }
  }

  useEffect(() => {
    dispatch(setActiveTab(EnumTabs.routes))
    dispatch(getRoutesListFromServer())
    dispatch(getRegionsListFromServer())

    return () => {
      dispatch(setLandmarksListFromServer([]))
      dispatch(setRegionsListFromServer([]))
      dispatch(setRoutesListFromServer([]))
    }
  }, [])

  useEffect(() => {
    if (regionId) {
      dispatch(getLandmarksByRegionIdFromServer(regionId))
    }
  }, [regionId])

  const [coverFilesState, setCoverFilesState] = useState<File[] | null>(null)
  const [mapFilesState, setMapFilesState] = useState<File[] | null>(null)

  return (
    <Container_row>
      {isLoading && <Loader loading={isLoading} />}
      {landmarksIsLoading && <Loader loading={landmarksIsLoading} />}

      <Fieldset>
        <Legend>Создание маршрута</Legend>

        <Select list={regionsList} onChange={regionsSelect} selectId="regions" selectName="regions" label="Регион" />

        <Label htmlFor="title">
          Название маршрута ({routeTitle ? routeTitle.length : ''} из <Warning>50</Warning> знаков)
        </Label>
        <Input_mb
          onInput={onCreateRouteTitleInput}
          type="text"
          id="title"
          name="title"
          value={routeTitle}
          maxLength={50}
        />

        <Label htmlFor="duration">Длительность маршрута (в минутах)</Label>
        <Input_mb onInput={onCreateDurationInput} type="number" id="duration" name="duration" value={routeDuration} />

        <Label htmlFor="nearbyStopAddress">
          Адрес конца маршрута (улица, номер дома, {nearbyStopAddress ? nearbyStopAddress.length : ''} из{'  '}
          <Warning> 30</Warning> знаков)
        </Label>
        <Input_mb
          onInput={onNearbyStopAddressInput}
          type="text"
          id="nearbyStopAddress"
          name="nearbyStopAddress"
          value={nearbyStopAddress}
          maxLength={30}
        />

        <Label htmlFor="nearbyStopAddressMapUrl">Ссылка на карту конца маршрута (Яндекс или Google карты)</Label>
        <Input_mb
          onInput={onNearbyStopAddressMapUrlInput}
          type="text"
          id="nearbyStopAddressMapUrl"
          name="nearbyStopAddressMapUrl"
          value={nearbyStopAddressMapUrl}
        />

        <Select
          label="Ориентир (не обязательно)"
          list={landmarksList}
          onChange={landmarkSelect}
          selectId="landmarks"
          selectName="landmarks"
          disabled={!regionId}
        />

        <UploadFiles
          multiple={false}
          label="Выбрать обложку (изображение 512х512 пикселей, jpg)"
          inputId="cover"
          inputName="coverFile"
          state={coverFilesState}
          setState={setCoverFilesState}
          limitFilesCount={1}
        />

        <UploadFiles
          multiple={false}
          label="Выбрать карту (скриншот карты с маршрутом - 1440х646 пикселей, jpg)"
          inputId="map"
          inputName="mapFile"
          state={mapFilesState}
          setState={setMapFilesState}
          limitFilesCount={1}
        />

        <Button delBtn={false} title="Создать маршрут" onClick={createRoute} />
      </Fieldset>

      <Fieldset>
        <Legend>Список всех маршрутов</Legend>
        <List>
          <RowTitle>
            <Item>Название маршрута</Item>
            <Item>Кол-во точек и просмотров</Item>
          </RowTitle>
          {routes &&
            routes.map((r) => {
              return (
                <Row key={r.id} onClick={() => onRouteClick(r.id)}>
                  <Item>{r.title}</Item>
                  <Item>
                    {r.markersCount} т., просмотров - {r.viewCount}
                  </Item>
                </Row>
              )
            })}
        </List>
      </Fieldset>
    </Container_row>
  )
}

import React, { FC } from 'react'
import styled from 'styled-components'
import { ReactComponent as ArrowIcon } from 'assets/arrow.svg'

type TProps = {
  onClick: () => void
}

export const BackBtn: FC<TProps> = (props: TProps) => {
  return (
    <Back onClick={props.onClick}>
      <StyledArrowIcon />
      <span>Назад</span>
    </Back>
  )
}
const StyledArrowIcon = styled(ArrowIcon)`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`

const Back = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 20px;
  &:hover {
    text-shadow: black 1px 0 10px;
  }
`

import React, { FormEvent, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deleteRegionFromServer, editRegionOnServer, getRegionFromServer } from 'store/modules/regions/actions'
import { getRegion, getRegionsIsLoading } from 'store/modules/regions/selectors'
import { Loader } from 'components/loader'
import { Button } from 'components/button'
import { setRegionFromServer } from 'store/modules/regions/reducer'
import { Fieldset, Legend } from 'components/styles/fieldset'
import { Input_mb, Label } from 'components/styles/form'
import { BackBtn } from 'components/backBtn'
import { Container_column } from 'components/styles/container'

export const EditRegionPage = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const region = useSelector(getRegion)
  const isLoading = useSelector(getRegionsIsLoading)
  const [regionTitle, setRegionTitle] = useState('')

  const goBack = () => history.goBack()

  const deleteRegion = () => {
    const c = confirm('Вы точно хотите удалить регион?')
    if (c) {
      dispatch(deleteRegionFromServer(id, goBack))
    }
  }

  const onRegionTitleInput = (e: FormEvent<HTMLInputElement>) => setRegionTitle(e.currentTarget.value)

  const editRegion = () => dispatch(editRegionOnServer(id, { title: regionTitle }, goBack))

  useEffect(() => {
    dispatch(getRegionFromServer(id))
    return () => {
      dispatch(setRegionFromServer(null))
    }
  }, [])

  useEffect(() => {
    if (region) {
      setRegionTitle(region.title)
    }
  }, [region])

  return (
    <Container_column>
      {isLoading && <Loader loading={isLoading} />}

      <BackBtn onClick={goBack} />
      <Fieldset>
        <Legend>Редактирование региона</Legend>
        <Label htmlFor="title">Название региона</Label>
        <Input_mb onInput={onRegionTitleInput} type="text" name="title" value={regionTitle} />
        <Button delBtn={false} title="Сохранить изменения" onClick={editRegion} />
      </Fieldset>
      <Button delBtn title="Удалить регион" onClick={deleteRegion} />
    </Container_column>
  )
}

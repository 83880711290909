import styled from 'styled-components'

export const CoverImg = styled.img`
  height: 64px;
  width: 64px;
  margin-bottom: 20px;
`

export const MapImg = styled.img`
  height: 64px;
  width: 144px;
  margin-bottom: 20px;
`

export const SliderImg = styled.img`
  height: 128px;
  width: 96px;
  margin-bottom: 20px;
`

export const Audio = styled.audio`
  height: 32px;
  width: 100%;
  margin-bottom: 20px;
`

import { request } from 'api'
import { setRegionFromServer, setRegionsIsLoading, setRegionsListFromServer } from 'store/modules/regions/reducer'
import { AppDispatch } from 'store/store'
import { setError } from 'store/modules/errors/reducer'
import { TCreateRegion } from 'store/modules/regions/types'

export const createRegionOnServer = (data: TCreateRegion, updateRegionsList: () => void) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setRegionsIsLoading(true))
    try {
      await request.post('/regions', data)
      updateRegionsList()
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setRegionsIsLoading(false))
    }
  }
}

export const editRegionOnServer = (id: string, data: TCreateRegion, goBack: () => void) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setRegionsIsLoading(true))
    try {
      await request.patch(`/regions/${id}`, data)
      goBack()
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setRegionsIsLoading(false))
    }
  }
}

export const getRegionsListFromServer = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(setRegionsIsLoading(true))
    try {
      const response = await request.get('/regions')
      dispatch(setRegionsListFromServer(response.data.data))
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setRegionsIsLoading(false))
    }
  }
}

export const getRegionFromServer = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setRegionsIsLoading(true))
    try {
      const response = await request.get(`/regions/${id}`)
      dispatch(setRegionFromServer(response.data.data))
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setRegionsIsLoading(false))
    }
  }
}

export const deleteRegionFromServer = (id: string, goBack: () => void) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setRegionsIsLoading(true))
    try {
      await request.delete(`/regions/${id}`)
      goBack()
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setRegionsIsLoading(false))
    }
  }
}

import React, { FormEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab } from 'store/modules/ui/reducer'
import { EnumTabs } from 'store/modules/ui/types'
import { createRegionOnServer, getRegionsListFromServer } from 'store/modules/regions/actions'
import { getRegionsIsLoading, getRegionsList } from 'store/modules/regions/selectors'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import { Loader } from 'components/loader'
import { Button } from 'components/button'
import { Item, List, Row } from 'components/styles/list'
import { Fieldset, Legend } from 'components/styles/fieldset'
import { Input_mb, Label } from 'components/styles/form'
import { Container_column } from 'components/styles/container'

export const RegionsPage = () => {
  const dispatch = useDispatch()
  const regionsList = useSelector(getRegionsList)
  const isLoading = useSelector(getRegionsIsLoading)
  const history = useHistory()

  const [regionTitle, setRegionTitle] = useState('')

  const onRegionClick = (id: string) => history.push(Routes.editRegion + '/' + id)
  const onRegionTitleInput = (e: FormEvent<HTMLInputElement>) => setRegionTitle(e.currentTarget.value)
  const updateRegionsList = () => dispatch(getRegionsListFromServer())
  const createRegion = () => {
    if (regionTitle) {
      dispatch(createRegionOnServer({ title: regionTitle }, updateRegionsList))
      setRegionTitle('')
    }
  }

  useEffect(() => {
    dispatch(setActiveTab(EnumTabs.regions))
    dispatch(getRegionsListFromServer())
  }, [])

  return (
    <Container_column>
      {isLoading && <Loader loading={isLoading} />}
      <Fieldset>
        <Legend>Создание региона</Legend>
        <Label htmlFor="title">Название региона</Label>
        <Input_mb onInput={onRegionTitleInput} type="text" name="title" value={regionTitle} />
        <Button delBtn={false} title="Создать регион" onClick={createRegion} />
      </Fieldset>

      <Fieldset>
        <Legend>Список регионов:</Legend>
        <List>
          {regionsList.map((r) => {
            return (
              <Row key={r.id} onClick={() => onRegionClick(r.id)}>
                <Item>{r.title}</Item>
                <Item />
              </Row>
            )
          })}
        </List>
      </Fieldset>
    </Container_column>
  )
}

import React, { FC } from 'react'
import { Header } from './components/header'
import { Route, Switch, useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import styled from 'styled-components'
import { Footer } from './components/footer'
import { NotificationPopUp } from 'components/notificationPopUp'
import { useDispatch, useSelector } from 'react-redux'
import { setError } from 'store/modules/errors/reducer'
import { getError } from 'store/modules/errors/selectors'
import { DefaultThemeProps } from 'styles/types'
import { MobileNavigation, Navigation } from 'pages/home/components/navigation'
import { getIsMobileDevise, getShowMenu } from 'store/modules/ui/selectors'
import { RegionsPage } from 'pages/regions'
import { LandmarksPage } from 'pages/landmarks'
import { RoutesPage } from 'pages/routes'
import { MarksPage } from 'pages/markers'
import { EditRegionPage } from 'pages/regionEdit'
import { EditLandmarkPage } from 'pages/landmarkEdit'
import { EditRoutePage } from 'pages/routeEdit'
import { EditMarkerPage } from 'pages/markerEdit'

export const Home: FC = () => {
  const dispatch = useDispatch()
  const error = useSelector(getError)
  const isMobile = useSelector(getIsMobileDevise)
  const showMenu = useSelector(getShowMenu)
  const history = useHistory()

  if (error?.statusCode === 401) {
    history.push(Routes.auth)
  }
  if (error?.statusCode === 404) {
    history.push(Routes.error)
  }

  return (
    <Container>
      {error && error.statusCode !== 404 && (
        <NotificationPopUp
          isError={true}
          title="Внимание"
          errorObj={error}
          closePopUp={() => dispatch(setError(null))}
        />
      )}

      {isMobile ? showMenu && <MobileNavigation /> : <Navigation />}

      <Wrapper>
        <Header />

        <Switch>
          <Route exact path={Routes.regions} component={RegionsPage} />
          <Route exact path={Routes.routes} component={RoutesPage} />
          <Route exact path={Routes.landmarks} component={LandmarksPage} />
          <Route exact path={Routes.marks} component={MarksPage} />
          <Route exact path={Routes.editRegion + '/:id'} component={EditRegionPage} />
          <Route exact path={Routes.editLandmark + '/:id'} component={EditLandmarkPage} />
          <Route exact path={Routes.editRoute + '/:id'} component={EditRoutePage} />
          <Route exact path={Routes.editMarker + '/:id'} component={EditMarkerPage} />
        </Switch>

        <Footer />
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  min-width: 360px;
  display: flex;
  height: 100vh;
  background-color: ${(props: DefaultThemeProps) => props.theme.background.primary};
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

import { request } from 'api'
import { TMarker } from 'store/modules/markers/types'

export const uploadFiles = async (files: File[], url: string, name: string) => {
  const filesIds: string[] = []

  if (files && files.length !== 0) {
    for (let file = 0; file < files.length; file++) {
      const formData = new FormData()
      formData.append(name, files[file])

      const response = await request.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      filesIds.push(response.data.data[0].id)
    }

    return filesIds
  }
}

export const deleteAudiosInMarker = async (marker: TMarker) => {
  if (marker.audios && marker.audios.length > 0) {
    for (const i of marker.audios) {
      await request.delete(`/files/audio/${i.id}`)
    }
  }
}

export const deleteImagesInMarker = async (marker: TMarker) => {
  if (marker.gallery && marker.gallery.length > 0) {
    for (const i of marker.gallery) {
      await request.delete(`/files/image/${i.id}`)
    }
  }
}

export type TThemeState = {
  theme: string
  activeTab: string
  mobileDevise: boolean
  showMenu: boolean
}

export enum EnumTabs {
  regions = 'regions',
  landmarks = 'landmarks',
  marks = 'marks',
  routes = 'routes',
}

import { Container_column } from 'components/styles/container'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Loader } from 'components/loader'
import { BackBtn } from 'components/backBtn'
import { Fieldset, Legend } from 'components/styles/fieldset'
import { useDispatch, useSelector } from 'react-redux'
import { getMarker, getMarkersIsLoading } from 'store/modules/markers/selectors'
import { Button } from 'components/button'
import { deleteMarkerFromServer, editMarkerOnServer, getMarkerFromServer } from 'store/modules/markers/actions'
import { Input_mb, Label, Textarea, Warning } from 'components/styles/form'
import { Select } from 'components/select'
import { UploadFiles } from 'components/uploadFiles'
import { getRoutesListFromServer } from 'store/modules/routes/actions'
import { setRoutesListFromServer } from 'store/modules/routes/reducer'
import { setMarkerFromServer } from 'store/modules/markers/reducer'
import { getRoutesList } from 'store/modules/routes/selectors'
import styled from 'styled-components'
import { Audio, SliderImg } from 'components/styles/img-audio'
import { config } from 'config/config'

export const EditMarkerPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const isLoading = useSelector(getMarkersIsLoading)
  const routes = useSelector(getRoutesList)
  const marker = useSelector(getMarker)

  const goBack = () => history.goBack()

  const deleteMarker = () => {
    const c = confirm('Вы точно хотите удалить точку маршрута?')
    if (c && marker) {
      dispatch(deleteMarkerFromServer(id, marker, goBack))
    }
  }

  useEffect(() => {
    dispatch(getMarkerFromServer(id))
    dispatch(getRoutesListFromServer())

    return () => {
      dispatch(setRoutesListFromServer([]))
      dispatch(setMarkerFromServer(null))
    }
  }, [])

  useEffect(() => {
    if (marker) {
      setIndexNumber(marker.indexNumber)
      setTitle(marker.title)
      setAddress(marker.address)
      setAddressMapUrl(marker.addressMapUrl)
      setDescriptionTitle(marker.descriptionTitle)
      setDescription(marker.description)
      setRouteId(marker.route.id)
      setImageFilesState(null)
      setAudioFilesState(null)
    }
  }, [marker])

  const [indexNumber, setIndexNumber] = useState<string | number>('')
  const onIndexNumberInput = (e: FormEvent<HTMLInputElement>) => setIndexNumber(+e.currentTarget.value)

  const [title, setTitle] = useState('')
  const onTitleInput = (e: FormEvent<HTMLInputElement>) => setTitle(e.currentTarget.value)

  const [address, setAddress] = useState('')
  const onAddressInput = (e: FormEvent<HTMLInputElement>) => setAddress(e.currentTarget.value)

  const [addressMapUrl, setAddressMapUrl] = useState('')
  const onAddressMapUrlInput = (e: FormEvent<HTMLInputElement>) => setAddressMapUrl(e.currentTarget.value)

  const [descriptionTitle, setDescriptionTitle] = useState('')
  const onDescriptionTitleInput = (e: FormEvent<HTMLInputElement>) => setDescriptionTitle(e.currentTarget.value)

  const [description, setDescription] = useState('')
  const onDescription = (e: FormEvent<HTMLTextAreaElement>) => setDescription(e.currentTarget.value)

  const [routeId, setRouteId] = useState<string | undefined>(undefined)
  const routesSelect = (e: ChangeEvent<HTMLSelectElement>) => setRouteId(e.currentTarget.value)

  const [imageFilesState, setImageFilesState] = useState<File[] | null>(null)
  const [audioFilesState, setAudioFilesState] = useState<File[] | null>(null)

  const editMarker = () => {
    if (title.length > 70) {
      alert('Название должно быть меньше 70 знаков')
      return
    }

    if (address.length > 30) {
      alert('Адрес должен быть меньше 30 знаков')
      return
    }

    if (description.length > 2000) {
      alert('Описание точки должно быть меньше 2000 знаков')
      return
    }

    if (indexNumber && title && address && descriptionTitle && description && routeId && marker) {
      dispatch(
        editMarkerOnServer(
          marker,
          id,
          +indexNumber,
          title,
          address,
          addressMapUrl,
          descriptionTitle,
          description,
          routeId,
          imageFilesState,
          audioFilesState,
          goBack,
        ),
      )
    }
  }

  return (
    <Container_column>
      {isLoading && <Loader loading={isLoading} />}

      <BackBtn onClick={goBack} />

      <Fieldset>
        <Legend>Редактирование точки маршрута</Legend>

        <Select
          label="Маршрут"
          list={routes}
          onChange={routesSelect}
          selectId="routes"
          selectName="routes"
          marker={marker}
          selectedId={marker ? marker.route.id : undefined}
        />

        <Label htmlFor="index">Порядковый номер точки</Label>
        <Input_mb onInput={onIndexNumberInput} type="number" id="index" name="index" value={indexNumber} />

        <Label htmlFor="title">
          Название ({title ? title.length : ''} из <Warning>70</Warning> знаков)
        </Label>
        <Input_mb onInput={onTitleInput} type="text" id="title" name="title" value={title} maxLength={70} />

        <Label htmlFor="address">
          Адрес (улица, номер дома, {address ? address.length : ''} из <Warning>30</Warning> знаков)
        </Label>
        <Input_mb onInput={onAddressInput} type="text" id="address" name="address" value={address} maxLength={30} />

        <Label htmlFor="addressMapUrl">Ссылка на карту (Яндекс или Google карты)</Label>
        <Input_mb
          onInput={onAddressMapUrlInput}
          type="text"
          id="addressMapUrl"
          name="addressMapUrl"
          value={addressMapUrl}
        />

        <Label htmlFor="descriptionTitle">Заголовок описания</Label>
        <Input_mb
          onInput={onDescriptionTitleInput}
          type="text"
          id="descriptionTitle"
          name="descriptionTitle"
          value={descriptionTitle}
        />

        <Label htmlFor="description">
          Описание ({description ? description.length : ''} из <Warning>2000</Warning> знаков)
        </Label>
        <Textarea onInput={onDescription} id="description" name="description" value={description} maxLength={2000} />

        <UploadFiles
          multiple={true}
          label="Выбрать изображения для слайдера (1280х960 пикселей, jpg)"
          inputId="image"
          inputName="imageFile"
          state={imageFilesState}
          setState={setImageFilesState}
          limitFilesCount={5}
        />

        {marker && marker.gallery && marker.gallery.length > 0 && (
          <>
            <Files>Прикрепленные файлы:</Files>
            {marker.gallery.map((i) => (
              <>
                <File key={i.id}>- {i.originalName}</File>
                <SliderImg src={config.baseUrl + '/' + i.source} alt="image" />
              </>
            ))}
          </>
        )}

        <UploadFiles
          multiple={true}
          label="Выбрать аудио (.wav)"
          inputId="audio"
          inputName="audioFile"
          state={audioFilesState}
          setState={setAudioFilesState}
          limitFilesCount={4}
        />

        {marker && marker.audios && marker.audios.length > 0 && (
          <>
            <Files>Прикрепленные файлы:</Files>
            {marker.audios.map((i) => (
              <>
                <File key={i.id}>- {i.originalName}</File>
                <Audio src={config.baseUrl + '/' + i.source} controls />
              </>
            ))}
          </>
        )}

        <Button delBtn={false} title="Сохранить" onClick={editMarker} />
      </Fieldset>
      <Button delBtn title="Удалить точку маршрута" onClick={deleteMarker} />
    </Container_column>
  )
}

const Files = styled.div`
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  margin-bottom: 10px;
`

const File = styled.div`
  margin-bottom: 5px;
`

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TLandmark, TLandmarkInList, TLandmarksState } from 'store/modules/landmarks/types'
import { TRouteInList } from 'store/modules/routes/types'

const initialState: TLandmarksState = {
  landmarksIsLoading: false,
  landmark: null,
  landmarksList: [],
  routesInLandmark: [],
}

const userSlice = createSlice({
  name: 'landmarks',
  initialState,
  reducers: {
    setLandmarksIsLoading(state: TLandmarksState, action: PayloadAction<boolean>) {
      state.landmarksIsLoading = action.payload
    },
    setLandmarksListFromServer(state: TLandmarksState, action: PayloadAction<TLandmarkInList[]>) {
      state.landmarksList = action.payload
    },
    setLandmarkFromServer(state: TLandmarksState, action: PayloadAction<TLandmark | null>) {
      state.landmark = action.payload
    },
    setRoutesInLandmarkFromServer(state: TLandmarksState, action: PayloadAction<TRouteInList[]>) {
      state.routesInLandmark = action.payload
    },
  },
})

export const {
  setLandmarksIsLoading,
  setLandmarksListFromServer,
  setLandmarkFromServer,
  setRoutesInLandmarkFromServer,
} = userSlice.actions

export default userSlice.reducer

import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'components/loader'
import { Button } from 'components/button'
import { getLandmark, getLandmarksIsLoading, getRoutesInLandmark } from 'store/modules/landmarks/selectors'
import {
  deleteLandmarkFromServer,
  editLandmarkOnServer,
  getLandmarkFromServer,
  getRoutesInLandmarkFromServer,
} from 'store/modules/landmarks/actions'
import { setLandmarkFromServer, setRoutesInLandmarkFromServer } from 'store/modules/landmarks/reducer'
import { getRegionsList } from 'store/modules/regions/selectors'
import { Fieldset, Legend } from 'components/styles/fieldset'
import { Input_mb, Label } from 'components/styles/form'
import { Select } from 'components/select'
import { BackBtn } from 'components/backBtn'
import { Container_column } from 'components/styles/container'
import { Item, List, Row } from 'components/styles/list'
import { Routes } from 'routes/routes'
import styled from 'styled-components'

export const EditLandmarkPage = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const landmark = useSelector(getLandmark)
  const isLoading = useSelector(getLandmarksIsLoading)
  const regionsList = useSelector(getRegionsList)
  const routesInLandmark = useSelector(getRoutesInLandmark)

  const [landmarkTitle, setLandmarkTitle] = useState('')
  const [editLandmarkRegionId, setEditLandmarkRegionId] = useState<string | undefined>(undefined)
  const editLandmarkSelect = (e: ChangeEvent<HTMLSelectElement>) => setEditLandmarkRegionId(e.currentTarget.value)

  const goBack = () => history.goBack()

  const deleteLandmark = () => {
    const c = confirm('Вы точно хотите удалить ориентир?')
    if (c) {
      dispatch(deleteLandmarkFromServer(id, goBack))
    }
  }

  const onLandmarkTitleInput = (e: FormEvent<HTMLInputElement>) => setLandmarkTitle(e.currentTarget.value)

  const editRegion = () => {
    if (landmarkTitle && editLandmarkRegionId) {
      dispatch(editLandmarkOnServer(id, { title: landmarkTitle, regionId: editLandmarkRegionId }, goBack))
    }
  }

  useEffect(() => {
    dispatch(getLandmarkFromServer(id))
    dispatch(getRoutesInLandmarkFromServer(id))
    return () => {
      dispatch(setLandmarkFromServer(null))
      dispatch(setRoutesInLandmarkFromServer([]))
    }
  }, [])

  useEffect(() => {
    if (landmark) {
      setLandmarkTitle(landmark.title)
      setEditLandmarkRegionId(landmark.region.id)
    }
  }, [landmark])

  const onRouteClick = (id: string) => history.push(Routes.editRoute + '/' + id)

  return (
    <Container_column>
      {isLoading && <Loader loading={isLoading} />}

      <BackBtn onClick={goBack} />
      <Wrapper>
        <Fieldset>
          <Legend>Редактирование ориентира</Legend>
          <Label htmlFor="title">Название ориентира</Label>
          <Input_mb onInput={onLandmarkTitleInput} type="text" name="title" value={landmarkTitle} />

          <Select
            label="Выбор региона"
            list={regionsList}
            onChange={editLandmarkSelect}
            selectId="regions"
            selectName="regions"
            region={landmark ? landmark.region : undefined}
            selectedId={editLandmarkRegionId}
          />

          <Button delBtn={false} title="Сохранить изменения" onClick={editRegion} />
        </Fieldset>

        <Fieldset>
          <Legend>Список маршрутов</Legend>
          <List>
            {routesInLandmark.map((r) => {
              return (
                <Row key={r.id} onClick={() => onRouteClick(r.id)}>
                  <Item>{r.title}</Item>
                  <Item>{r.markersCount} т.</Item>
                </Row>
              )
            })}
          </List>
        </Fieldset>
      </Wrapper>
      <Button delBtn title="Удалить ориентир" onClick={deleteLandmark} />
    </Container_column>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

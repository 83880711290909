import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TMarker, TMarkersState } from 'store/modules/markers/types'

const initialState: TMarkersState = { markersIsLoading: false, marker: null }

const userSlice = createSlice({
  name: 'markers',
  initialState,
  reducers: {
    setMarkersIsLoading(state: TMarkersState, action: PayloadAction<boolean>) {
      state.markersIsLoading = action.payload
    },
    setMarkerFromServer(state: TMarkersState, action: PayloadAction<TMarker | null>) {
      state.marker = action.payload
    },
  },
})

export const { setMarkersIsLoading, setMarkerFromServer } = userSlice.actions

export default userSlice.reducer

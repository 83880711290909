import styled from 'styled-components'

export const Label = styled.label`
  margin-bottom: 5px;
`

export const Warn = styled.div`
  text-align: center;
  color: crimson;
`

export const Input_mb = styled.input`
  padding: 5px;
  margin-bottom: 10px;
`

export const Textarea = styled.textarea`
  resize: none;
  height: 200px;
  padding: 5px;
  margin-bottom: 10px;
`
export const Warning = styled.span`
  color: crimson;
`

import styled from 'styled-components'

export const Legend = styled.legend`
  margin-bottom: 20px;
`

export const Fieldset = styled.fieldset`
  width: 49%;
  max-width: 49%;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
  margin-bottom: 20px;
  padding: 10px;
`

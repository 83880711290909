import { request } from 'api'
import { AppDispatch } from 'store/store'
import { setError } from 'store/modules/errors/reducer'
import {
  setLandmarkFromServer,
  setLandmarksIsLoading,
  setLandmarksListFromServer,
  setRoutesInLandmarkFromServer,
} from 'store/modules/landmarks/reducer'
import { TCreateLandmark } from 'store/modules/landmarks/types'

export const createLandmarkOnServer = (data: TCreateLandmark, updateLandmarks: () => void) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLandmarksIsLoading(true))
    try {
      await request.post('/landmarks', data)
      updateLandmarks()
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setLandmarksIsLoading(false))
    }
  }
}

export const editLandmarkOnServer = (id: string, data: TCreateLandmark, goBack: () => void) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLandmarksIsLoading(true))
    try {
      await request.patch(`/landmarks/${id}`, data)
      goBack()
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setLandmarksIsLoading(false))
    }
  }
}

export const getLandmarkFromServer = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLandmarksIsLoading(true))
    try {
      const response = await request.get(`/landmarks/${id}`)
      dispatch(setLandmarkFromServer(response.data.data))
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setLandmarksIsLoading(false))
    }
  }
}

export const deleteLandmarkFromServer = (id: string, goBack: () => void) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLandmarksIsLoading(true))
    try {
      await request.delete(`/landmarks/${id}`)
      goBack()
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setLandmarksIsLoading(false))
    }
  }
}

export const getLandmarksByRegionIdFromServer = (regionId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLandmarksIsLoading(true))
    try {
      const response = await request.get(`/regions/${regionId}/landmarks`)
      dispatch(setLandmarksListFromServer(response.data.data))
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setLandmarksIsLoading(false))
    }
  }
}

export const getRoutesInLandmarkFromServer = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setLandmarksIsLoading(true))
    try {
      const response = await request.get(`/landmarks/${id}/routes`)
      dispatch(setRoutesInLandmarkFromServer(response.data.data))
    } catch (error) {
      dispatch(setError(error.response.data))
    } finally {
      dispatch(setLandmarksIsLoading(false))
    }
  }
}

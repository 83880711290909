import styled from 'styled-components'

export const List = styled.div`
  max-height: 60vh;
  overflow: auto;
  padding-right: 40px;
`

export const SmallList = styled(List)`
  display: grid;
  max-height: 30vh;
`

export const Item = styled.div``

export const RowTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid black;
  padding: 0 0 5px 5px;
  margin-bottom: 10px;
`

export const Row = styled.div`
  width: 100%;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid transparent;
  border-left: 1px solid transparent;
  padding: 5px 0 5px 5px;

  &:hover {
    border-bottom: 1px solid black;
    border-left: 1px solid black;
  }
`

import React, { ChangeEvent, FC } from 'react'
import styled from 'styled-components'
import { Label } from 'components/styles/form'
import { TRegionInList } from 'store/modules/regions/types'
import { TMarker } from 'store/modules/markers/types'

type TProps = {
  label: string
  list: { id: string | undefined; title: string }[]
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
  selectId: string
  selectName: string
  disabled?: boolean
  marker?: TMarker | null
  region?: TRegionInList | null
  landmark?: {
    id: string
    title: string
  } | null
  selectedId?: string
}

export const Select: FC<TProps> = (props: TProps) => {
  const newList = [...props.list]
  newList.unshift({ id: 'undefined', title: 'Выберите...' })

  return (
    <>
      <Label htmlFor={props.selectId}>
        {props.label}
        <Span>: {props.marker?.route.title || props.region?.title || props.landmark?.title || ''}</Span>
      </Label>
      <SSelect
        id={props.selectId}
        onChange={props.onChange}
        name={props.selectName}
        disabled={props.disabled}
        value={props.selectedId ? props.selectedId : undefined}
      >
        {newList &&
          newList.map((i) => (
            <Option key={i.id} value={i.id}>
              {i.title}
            </Option>
          ))}
      </SSelect>
    </>
  )
}

const SSelect = styled.select`
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
`

const Span = styled.span`
  color: blue;
  font-size: 12px;
`

const Option = styled.option`
  width: 400px !important;
  overflow: hidden;
`

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TRegion, TRegionInList, TRegionsState } from 'store/modules/regions/types'

const initialState: TRegionsState = { regionsIsLoading: false, region: null, regionList: [] }

const userSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    setRegionsIsLoading(state: TRegionsState, action: PayloadAction<boolean>) {
      state.regionsIsLoading = action.payload
    },
    setRegionsListFromServer(state: TRegionsState, action: PayloadAction<TRegionInList[]>) {
      state.regionList = action.payload
    },
    setRegionFromServer(state: TRegionsState, action: PayloadAction<TRegion | null>) {
      state.region = action.payload
    },
  },
})

export const { setRegionsIsLoading, setRegionsListFromServer, setRegionFromServer } = userSlice.actions

export default userSlice.reducer

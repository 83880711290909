import React, { FC } from 'react'
import styled from 'styled-components'
import { DefaultThemeProps } from 'styles/types'

type TProps = {
  title: string
  onClick: () => void
  delBtn: boolean
}

export const Button: FC<TProps> = (props: TProps) => {
  return (
    <>
      {props.delBtn ? (
        <DelBtn onClick={props.onClick}>{props.title}</DelBtn>
      ) : (
        <Btn onClick={props.onClick}>{props.title}</Btn>
      )}
    </>
  )
}

const Btn = styled.button`
  align-self: center;
  border: 1px solid ${(props: DefaultThemeProps) => props.theme.text.primary};
  background-color: ${(props: DefaultThemeProps) => props.theme.background.primary};
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
  }
`

const DelBtn = styled.button`
  align-self: flex-start;
  background-color: crimson;
  color: white;
  border: 1px solid crimson;
  cursor: pointer;
  padding: 6px;
  margin-top: auto;
  &:hover {
    box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
  }
`

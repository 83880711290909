import React, { FC } from 'react'
import styled from 'styled-components'
import { DefaultThemeProps } from 'styles/types'

type TProps = {
  name: string
  onClick: (name: string) => void
}

export const FileInfoItem: FC<TProps> = (props: TProps) => {
  return (
    <Item>
      <Name>{props.name}</Name>
      <Del onClick={() => props.onClick(props.name)}>x</Del>
    </Item>
  )
}

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Name = styled.span`
  display: inline-block;
  overflow: hidden;
  width: 300px;
  text-overflow: ellipsis;
  color: ${(props: DefaultThemeProps) => props.theme.text.primary};
`

const Del = styled.span`
  cursor: pointer;
  color: crimson;
  font-size: 18px;
  padding: 5px;
`

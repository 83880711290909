import React, { FC } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import styled from 'styled-components'

type TProps = {
  loading: boolean
}

export const Loader: FC<TProps> = (props: TProps) => {
  return (
    <Container>
      <ClipLoader color={'blue'} loading={props.loading} size={50} />
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  display: grid;
  place-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(2px);
`

import styled from 'styled-components'

export const Container_column = styled.div`
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
`

export const Container_row = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`
